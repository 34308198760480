global.config = {
  google_api_key: process.env.VUE_APP_GOOGLE_API_KEY,
  googleGeocodeParams: {
    components: "country:it|administrative_area:Lombardia"
  },
  baseUrl: "",
  proximityDefaultDistance: "10000.0",
  showSectors: false,
  showTimeslotAutoassigned: false,
  hashNotReload: ["/cart/", "/payment"],
  filterEmptyCategory: true,
  dayWordsSaved: 30,
  version: process.env.VUE_APP_VERSION,
  acceptAlternatives: false,
  acceptAlternativesOnPromo: false,
  minProfileLevel: 2,
  registration: false,
  checkVersionInterval: 1000 * 60 * 10, //ogni 10 minuti
  reloadAfterMillis: 1000 * 60 * 30, //dopo 30 minuti di inattività restarta la app
  updateCart: true,
  domainWhitelist: [
    "laspesaonline.eurospin.it",
    "eurospin.it",
    "test-eurospin.digitelematica.org",
    "laspesaonlinedev.eurospin.it",
    "laspesaonlinetest.eurospin.it",
    "localhost"
  ],
  cartItemInfos: ["size", "custom_weight", "delivery_warehouse_id", "use_wpid"],
  analyticsClass: "analyticsServiceGTM",
  productDescriptionWarningsKeyList: [
    "productIdentifier",
    "warnings",
    "otherWarnings",
    "safetyPhrases",
    "clpImages",
    "aiseImages"
  ],
  defaultStoreName: process.env.VUE_APP_DEFAULT_STORE_NAME,
  defaultPageName: "Eurospin",
  retrieveCustomerLifetimeData: true,
  customCountries: [
    {
      countryId: 1,
      iso: "IT",
      name: "Italia"
    },
    {
      countryId: 2,
      iso: "MT",
      name: "Malta"
    },
    {
      countryId: 3,
      iso: "DE",
      name: "Germania"
    },
    /*{
      countryId: 4,
      iso: "RO",
      name: "Romania"
    },*/
    {
      countryId: 5,
      iso: "FR",
      name: "Francia"
    }
  ]
};
