<template>
  <div class="login fill-height">
    <v-container fluid fill-height class="pa-3 pa-sm-0">
      <!-- <v-layout> -->
      <v-layout column class="text-center py-6 grey--text text--darken-2">
        <!-- </v-layout> -->
        <v-flex xs12 mt-5>
          <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
            <v-card class="elevation-0">
              <div class="d-flex align-center flex-column text-center">
                <div class="login-title text-h4">{{ $t("login.title") }}</div>

                <h3 class="font-weight-regular login-sub ">
                  <i18n path="login.subtitle.text" tag="p" class="mt-2">
                    <template v-slot:link
                      ><a :href="redirectUrl">
                        {{ $t("login.subtitle.link") }}
                      </a>
                    </template>
                  </i18n>
                </h3>
                <fade-loader
                  :loading="true"
                  color="#005db9"
                  class="my-10"
                  width="10px"
                  height="20px"
                  radius="40px"
                />
              </div>
              <v-card-text v-if="dev">
                <h1 class="text-left primary--text text--lighten-1 my-8">
                  {{ $t("login.titleDev") }}
                </h1>
                <div class="login-form d-flex flex-column">
                  <h3 class="label mb-2">{{ $t("login.username") }}</h3>
                  <v-text-field
                    v-model="email"
                    class="email-input mb-4"
                    :rules="emailRules"
                    :error-messages="errors"
                    type="email"
                    autocomplete="off"
                    required
                    solo
                    flat
                    filled
                  ></v-text-field>
                  <h3 class="label mb-2">{{ $t("login.password") }}</h3>
                  <v-text-field
                    v-model="password"
                    class="password-input mb-4"
                    autocomplete="off"
                    :append-icon="showPassword ? '$eye' : '$eyeOff'"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="passwordRules"
                    @click:append="toggleShowPassword"
                    required
                    solo
                    flat
                    filled
                  ></v-text-field>
                </div>
                <div
                  class="d-flex align-right justify-center justify-sm-end remember-password"
                >
                  <div
                    class="recover-pass-wrap text-right"
                    v-if="enableRegistration"
                  >
                    <a @click="goToResetPassword" class="recover-password">
                      {{ $t("login.rememberPassword") }}
                    </a>
                  </div>
                </div>

                <ResponseMessage :response="response" />
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-btn
                      :loading="loading"
                      type="submit"
                      class="login-button primary lighten-1"
                      x-large
                      width="100%"
                      depressed
                    >
                      {{ $t("login.buttonLogin") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <div
                  class="d-flex flex-column justify-content-center align-center mt-4 register-wrapper"
                  v-if="enableRegistration"
                >
                  <div class="register-label mb-5">
                    <h3>
                      {{ $t("login.messageRegister") }}
                    </h3>
                  </div>
                  <v-btn
                    @click="handleRegister"
                    class="register-button"
                    x-large
                    outlined
                    width="100%"
                    depressed
                  >
                    {{ $t("login.buttonRegister") }}
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<style scoped lang="scss">
h1 {
  font-weight: 400 !important;
}
a {
  text-decoration: none !important;
}
</style>
<script>
import ResponseMessage from "@/components/ResponseMessage";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import form from "~/mixins/form";
import { mapActions } from "vuex";
import {
  requiredValue,
  isEmail,
  isPasswordMinimumLength
} from "@/validator/validationRules";

export default {
  name: "Login",
  props: {
    hideRegistration: { type: Boolean, required: false, default: false }
  },
  mixins: [form],
  components: { ResponseMessage, FadeLoader },
  data() {
    return {
      loading: null,
      showPassword: false,
      email: "",
      password: "",
      rememberMe: true,
      response: {},
      valid: true,
      lazy: true,
      redirectUrl: null,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      errors: [],
      invalid: null
    };
  },
  computed: {
    dev() {
      return window.location.hostname === "localhost";
      // return false;
    },
    enableRegistration() {
      return global.config.registration;
    }
  },
  methods: {
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart",
      samlLogin: "cart/doSamlLogin"
    }),
    goToResetPassword() {
      this.$router.push({
        name: "ResetPasswordRequest"
      });
      this.$emit("submit", false);
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async handleSubmit(e) {
      e.preventDefault();
      if (this.password.length > 0) {
        try {
          this.loading = true;
          this.response = {};
          await this.doLogin({
            email: this.email,
            password: this.password,
            rememberMe: this.rememberMe
          });
          this.$emit("submit", true);
        } catch (response) {
          this.response = response;
        } finally {
          this.loading = false;
        }
      }
    },
    handleRegister() {
      this.$router.push({
        name: "RegistrationUser",
        path: "/registration-user"
      });
      this.$emit("submit", false);
    }
  },
  async mounted() {
    let link = await this.samlLogin({ additionalUrl: "?loginCompleted=true" });
    if (link) {
      this.redirectUrl = link.loginRedirectUrl;
      if (!this.dev) {
        setTimeout(() => {
          window.location.href = link.loginRedirectUrl;
        }, 2000);
      }
    }
  }
};
</script>
