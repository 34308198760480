var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"invite-friend-baner-card d-flex flex-column flex-sm-row justify-center align-center",attrs:{"tile":"","elevation":"0"}},[_c('img',{staticClass:"gift-icon",attrs:{"src":"img_layout/gift.svg","width":"58","color":"primary"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('ebsn-meta',{class:'invite-friend-title text-center',attrs:{"target":_vm.config,"path":"categoryblocktype_InviteFriendBaner.TITLE","tag":"h2"}}),_c('div',{staticClass:"text-center"},[_c('ebsn-meta',{class:'invite-friend-description',attrs:{"target":_vm.config,"path":"categoryblocktype_InviteFriendBaner.DESCRIPTION","tag":"span"}}),_c('span',{staticClass:"invite-friend-code"},[_vm._v(" "+_vm._s(_vm.code))])],1)],1),_c('v-btn',{attrs:{"large":"","tile":"","elevation":"0","color":"primary","block":_vm.$vuetify.breakpoint.xsOnly,"to":{
        name: 'Category',
        params: {
          pathMatch: _vm.$ebsn.meta(
            this.config,
            'categoryblocktype_InviteFriendBaner.CATEGORY.slug'
          )
        }
      }}},[_vm._v(_vm._s(_vm.$t("invite.cta")))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }