<template>
  <v-container>
    <v-layout fill-height column>
      <div
        class="d-flex flex-column justify-center mb-2 timeslot-selector"
        v-if="categoryTitle"
      >
        <h3 class="font-weight-bold text-center ma-4 mb-0 ">
          {{ categoryTitle }}
        </h3>
        <h5
          class="subtitle-1 text-center"
          v-if="categoryDescription"
          v-html="categoryDescription"
        ></h5>

        <!-- header banner -->
        <!-- 
        <div class="mt-5" v-if="headerProposals.length > 0">
          <component
            :is="headerMode"
            :title="headerTitle"
            :proposals="headerProposals"
            :autoplay="true"
            paginationClass="pagination-header-address"
            cols="1"
            sm="auto"
            md="auto"
            lg="auto"
          />
        </div>
       -->

        <!-- delivery service selector -->
        <v-row
          no-gutters
          class="service-block-container align-center justify-space-around my-2 my-sm-6"
          v-if="deliveryServices.length > 1"
        >
          <v-col
            class="service-block px-0 px-sm-1"
            v-for="service in deliveryServices"
            :key="service.deliveryServiceId"
            @click="changeDeliveryService(service)"
            v-model="tab"
            cols="12"
            :sm="deliveryServiceButtonsLength"
          >
            <div
              class="text-center px-5 py-2 py-sm-5 tab d-flex align-center justify-center rounded my-1 my-sm-0"
              :class="{
                selected:
                  deliveryService.deliveryServiceId ===
                  service.deliveryServiceId
              }"
            >
              <v-img
                class="mr-2"
                :src="getServiceParams(service.deliveryServiceId).icon"
                :alt="getServiceParams(service.deliveryServiceId).name"
                height="45"
                width="45"
                max-width="45"
                contain
              >
              </v-img>
              <span class="font-weight-bold text-uppercase">{{
                service.name
              }}</span>
            </div>
          </v-col>
        </v-row>
        <div
          class="font-weight-bold delivery-service-descr"
          v-html="deliveryService.descr"
          v-if="deliveryServices.length > 1 && !addAddressOpen"
        ></div>

        <div
          class="delivery-service-descr font-weight-bold primary--text text--lighten-1 mt-3"
          v-if="
            deliveryServices.length > 1 &&
              !addAddressOpen &&
              selectedDeliveryServiceId === 2
          "
        >
          I miei indirizzi
        </div>
      </div>
      <v-card :loading="loading" flat class="pb-2">
        <v-row
          no-gutters
          class="justify-center"
          v-if="selectedDeliveryServiceId != 2"
        >
          <!-- filtered search drive and locker -->
          <v-col :cols="12" :sm="8" class="d-flex">
            <vue-google-autocomplete
              v-if="googleEnabled"
              id="pac-input"
              classname="google-autocomplete-input address-to-search"
              ref="addresstosearchref"
              :placeholder="
                $t(
                  'navbar.service.' + selectedDeliveryServiceId + '.searchLabel'
                )
              "
              :types="'geocode'"
              v-on:placechanged="addressToSearchChanged"
              :enable-geolocation="true"
              country="it"
              @keyup.enter.native="searchFirst"
            >
            </vue-google-autocomplete>
            <v-btn
              class="search-autocomplete-btn"
              color="secondary"
              height="40"
              depressed
              @click="searchFirst"
            >
              <v-icon>$search</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4" class="d-flex justify-center ">
            <v-btn
              class="geolocate-btn primary--text text--lighten-1 mt-3 mt-sm-0"
              color="primary lighten-1"
              height="40"
              outlined
              depressed
              @click="geolocate()"
            >
              <v-icon small>$placeholder</v-icon>
              <span class="primary--text text--lighten-1">
                {{ $t("addresses.geolocateButton") }}
              </span>
            </v-btn>
          </v-col>
          <v-col
            class="d-flex align-start align-sm-center flex-column flex-sm-row mt-4 mt-sm-3 filter-text"
          >
            Filtra risultati entro:
            <v-radio-group
              class="ml-1 mt-0 mt-sm-4"
              dense
              v-model="distanceRange"
              row
              @change="distanceRangeChanged()"
            >
              <v-radio color="primary lighten-1" :value="10000">
                <template v-slot:label>
                  <span class="distance-label distance-radio-label">
                    10km
                  </span>
                </template>
              </v-radio>
              <v-radio color="primary lighten-1" :value="30000">
                <template v-slot:label>
                  <span class="distance-label distance-radio-label">
                    30km
                  </span>
                </template>
              </v-radio>
              <v-radio color="primary lighten-1" :value="60000">
                <template v-slot:label>
                  <span class="distance-label distance-radio-label">
                    60km
                  </span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <div class="warehouse-list-container hide-scrollbar">
          <!-- warehouse/address list -->
          <v-list
            dense
            class="warehouse-list py-0 py-sm-2"
            v-if="filteredAddresses.length > 0 && !addAddressOpen"
          >
            <v-list-item-group
              v-model="shippingAddress"
              :mandatory="selectedItemGroup != -1"
              color="primary"
            >
              <AddressListItem
                class="address-list-item"
                :addresses="filteredAddresses"
                :cart="cart"
                :shippingAddressSelected="shippingAddress"
                @setAddress="setAddress"
              />
            </v-list-item-group>
          </v-list>
          <div v-else-if="loading === false && selectedDeliveryServiceId != 2">
            <v-alert
              type="error"
              class="pa-3"
              v-html="
                $t('navbar.service.' + selectedDeliveryServiceId + '.noService')
              "
            >
            </v-alert>
          </div>

          <!-- near warehouse/address list -->
          <div
            v-if="suggestedNearAddresses && suggestedNearAddresses.length > 0"
            class="near-warehouse-container"
          >
            <span>Vicino a te è disponibile anche:</span>
            <v-list dense class="warehouse-list">
              <v-list-item-group
                v-model="suggestedNearShippingAddress"
                :mandatory="selectedItemGroup != -1"
                color="primary"
              >
                <AddressListItem
                  :addresses="suggestedNearAddresses"
                  :cart="cart"
                  :shippingAddressSelected="suggestedNearShippingAddress"
                  :showServiceIcon="true"
                  :deliveryServiceSelected="selectedDeliveryServiceId"
                  @setAddress="setAddress"
                />
              </v-list-item-group>
            </v-list>
            <!-- <span v-else class="d-block">Non ci sono </span> -->
          </div>
        </div>

        <div v-if="selectedDeliveryServiceId == 2">
          <!-- home delivery has add address instead of search -->
          <div v-if="!addAddressOpen" class="d-flex justify-end">
            <v-btn
              color="primary"
              height="40"
              depressed
              @click="openAddAddress()"
            >
              {{ $t("addresses.addAddressButton") }}
            </v-btn>
          </div>
          <EditAddress
            v-else
            :showBackButton="false"
            :openInModal="true"
            @cancelAddAddress="cancelAddAddress"
            @save="saveAddress"
          />
        </div>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import AddressListItem from "@/components/delivery/AddressListItem.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import EditAddress from "@/views/profile/EditAddress.vue";

import StoreService from "~/service/storeService";
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import gmapsInit from "@/plugins/gmapsinit";
import { mapState } from "vuex";
import get from "lodash/get";
import split from "lodash/split";

export default {
  name: "AddressSelector",
  components: {
    VueGoogleAutocomplete,
    AddressListItem,
    EditAddress
  },
  data() {
    return {
      google: null,
      addressToSearch: null,
      googleEnabled: false,
      store: {},
      addresses: [],
      suggestedNearAddresses: null,
      loading: null,
      shippingAddress: null,
      suggestedNearShippingAddress: null,
      deliveryService: null,
      distanceRange: 10000,
      tab: 2,
      searchText: null,
      category: {},
      selectedItemGroup: -1,
      isLoadingCities: false,
      addAddressOpen: false
    };
  },
  computed: {
    categoryTitle() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        split(this.category.name, "@")[0]
      );
    },
    categoryDescription() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    },
    filteredAddresses() {
      return this.addresses;
    },
    deliveryServiceButtonsLength() {
      return 12 / this.deliveryServices.length;
    },
    ...mapState({
      cart: ({ cart }) => cart.cart,
      selectedService: ({ cart }) => cart.selectedService
    }),
    selectedDeliveryServiceId() {
      return this.deliveryService ? this.deliveryService.deliveryServiceId : 0;
    },
    deliveryServices() {
      if (this.store && this.store.deliveryServices) {
        return this.store.deliveryServices.filter(button => {
          return button.showTab;
        });
      }
      return [];
    }
  },
  methods: {
    async remove(address) {
      let vm = this;
      const res = await vm.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        let response = await DeliveryService.removeAddress(address.addressId);
        vm.addresses = response.addresses;
      }
    },
    geolocate() {
      let vm = this;
      if (navigator.geolocation) {
        document.getElementById("pac-input").value = "";
        document.getElementById("pac-input").placeholder =
          "Localizzazione in corso...";
        navigator.geolocation.getCurrentPosition(
          function(position) {
            var latlng = new vm.google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            let addressData = null;
            var geocoder = new vm.google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, function(results, status) {
              if (status == vm.google.maps.GeocoderStatus.OK) {
                addressData = results[0];
                vm.addressToSearch = {
                  latitude: addressData.geometry.location.lat(),
                  longitude: addressData.geometry.location.lng()
                };
                document.getElementById("pac-input").placeholder = vm.$t(
                  "navbar.service." +
                    vm.selectedDeliveryServiceId +
                    ".searchLabel"
                );
                document.getElementById("pac-input").value =
                  addressData.formatted_address;
                vm.searchNearWarehouse();
              } else {
                console.log(
                  "Geocode was not successful for the following reason: "
                );
              }
            });
          },
          function(err) {
            console.log(err);
            alert(
              "è necessario abilitare la localizzazione per utilizzare questa funzione"
            );
          },
          { enableHighAccuracy: false, maximumAge: Infinity }
        );
      }
    },
    searchFirst() {
      // try get first suggestin google autocomplete
      let vm = this;
      let pacItem = document.getElementsByClassName("pac-item");
      if (pacItem.length > 0) {
        let addressData = null;
        let firstResult = document.getElementsByClassName("pac-item")[0]
          .innerText;
        let geocoder = new vm.google.maps.Geocoder();
        geocoder.geocode({ address: firstResult }, function(results, status) {
          if (status == vm.google.maps.GeocoderStatus.OK) {
            addressData = results[0];
            vm.addressToSearch = {
              latitude: addressData.geometry.location.lat(),
              longitude: addressData.geometry.location.lng()
            };
            // update search field text and start store locator search
            document.getElementById("pac-input").value =
              addressData.formatted_address;
            vm.searchNearWarehouse();
          } else {
            vm.addressToSearch = null;
          }
        });
      }
    },
    searchNearWarehouse() {
      this.fetchAddresses();
    },
    distanceRangeChanged() {
      if (this.addressToSearch) {
        this.searchNearWarehouse();
      }
    },
    addressToSearchChanged(address) {
      this.addressToSearch = {
        latitude: address.latitude,
        longitude: address.longitude
      };

      this.searchNearWarehouse();
    },
    edit(address) {
      let vm = this;
      vm.$router.push({
        path: "/profile/addresses/manage/" + address.addressId
      });
      vm.$emit("submit", true);
    },
    getServiceParams(serviceId) {
      return this.$t("navbar.service." + serviceId);
    },
    async fetchStore() {
      let vm = this;
      let store = await StoreService.getStoreById(vm.cart.store.storeId);
      vm.store = store;

      vm.store.deliveryServices.find(deliveryService => {
        if (
          deliveryService.deliveryServiceId ==
          vm.cart.shippingAddress.deliveryServiceId
        ) {
          vm.deliveryService = deliveryService;
        }
      });
    },
    changeDeliveryService(deliveryService) {
      this.addAddressOpen = false;
      this.searchText = null;
      let pacInput = document.getElementById("pac-input");
      if (pacInput) {
        pacInput.value = "";
      }
      this.addressToSearch = null;
      // let deliveryServiceId =
      //   deliveryService.deliveryServiceId || deliveryService;
      if (deliveryService && deliveryService.deliveryServiceId) {
        this.deliveryService = deliveryService;
      }
      this.fetchAddresses();
    },
    async fetchAddresses(deliveryServiceId) {
      let vm = this;
      vm.loading = true;
      let deliveryService = deliveryServiceId
        ? deliveryServiceId
        : this.selectedDeliveryServiceId;
      if (deliveryService == 2 || !this.addressToSearch) {
        vm.addresses = await StoreService.getAddressesByDeliveryService(
          deliveryService
        );
        vm.suggestedNearAddresses = null;
      } else {
        let result = await DeliveryService.getAddressByDeliveryService(
          deliveryService,
          this.addressToSearch.latitude,
          this.addressToSearch.longitude,
          this.distanceRange
        );
        vm.addresses = result.addresses;

        let resultSuggestedNearAddresses = await DeliveryService.getAddressByDeliveryService(
          deliveryService == 1 ? 4 : 1,
          this.addressToSearch.latitude,
          this.addressToSearch.longitude,
          this.distanceRange
        );
        vm.suggestedNearAddresses = resultSuggestedNearAddresses.addresses;
      }

      vm.addresses.find((address, index) => {
        if (address.addressId == vm.cart.shippingAddress.addressId) {
          vm.shippingAddress = index;
        }
      });
      vm.loading = false;
    },
    async setAddress(param) {
      let vm = this;
      if (param.index) {
        if (vm.shippingAddress === param.index) {
          return;
        }
      }
      this.loading = true;
      let data = await this.$store.dispatch("cart/setAddress", {
        addressType: param.address.addressType,
        addressId: param.address.addressId
      });

      this.loading = false;
      if (data) {
        vm.$emit("submit", true);
      }
    },
    getServiceImg(serviceId) {
      return this.$t("navbar.service." + serviceId + ".icon");
    },
    openAddAddress() {
      // this.$router.push({
      //   path: "/profile/addresses/manage"
      // });
      // this.$emit("submit", false);
      this.addAddressOpen = true;
    },
    cancelAddAddress() {
      this.addAddressOpen = false;
    },
    saveAddress(address) {
      console.log("address added");
      this.setAddress({ address: address });
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "service-selector"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  async created() {
    let vm = this;
    try {
      vm.google = await gmapsInit(global.config.google_api_key);
      if (vm.google) {
        vm.googleEnabled = true;
      }
    } catch (err) {
      console.log(err);
    }
    this.fetchStore();
    this.fetchAddresses(this.cart.shippingAddress.deliveryServiceId);
    this.fetchCategory();
  }
};
</script>

<style scoped lang="scss">
.tab {
  background: var(--v-grey-lighten3);
  border: 1px solid var(--v-grey-darken2);
  color: var(--v-primary-lighten1);
  min-height: 75px;
  font-size: 16px;
  line-height: 1.2;
  opacity: 0.5;
  cursor: pointer;

  &.selected {
    background: var(--v-grey-lighten3) !important;
    border: 2px solid var(--v-primary-lighten1);
    opacity: 1;
    color: var(--v-primary-lighten1);
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-height: 60px;
  }
}
.service-block-container {
  .service-block {
    &:first-child {
      padding-left: 0px !important;
    }
    &:last-child {
      padding-right: 0px !important;
    }
  }
}
.address-list-item {
  :deep(.v-list-item) {
    padding-left: 0px !important;
  }
}
.delivery-service-descr {
  font-size: 18px;
}
.search-autocomplete-btn {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.google-autocomplete-input {
  width: 100%;
  border: 1px solid var(--v-grey-base);
  border-radius: $border-radius-root 0px 0px $border-radius-root;
  padding: 6px 15px;
  font-size: 14px;
  &:focus {
    outline: none;
  }
}
.filter-text {
  font-size: 14px;
  .v-radio {
    .distance-label {
      font-size: 14px;
    }
  }
}
.warehouse-list-container {
  max-height: calc(100vh - 460px);
  @media #{map-get($display-breakpoints, 'xs-only')} {
    max-height: 400px;
  }
  overflow-y: auto;
}
.v-list.warehouse-list {
  .v-list-item {
    &:not(:last-child) {
      border-bottom: 1px solid var(--v-grey-lighten3);
    }
  }
  .v-list-item:last-child {
    border-bottom: 0;
  }
}
.near-warehouse-container {
  border-top: 2px solid var(--v-grey-base);
  margin-top: 10px;
  padding-top: 10px;
}
:deep(.edit-address) {
  .address-title {
    margin-top: 0px !important;
    h1 {
      font-size: 20px;
    }
  }
}
.theme--light.v-list-item--active::before {
  opacity: 0;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .theme--light.v-btn--outlined.geolocate-btn {
    border: 0;
  }
}
.hide-scrollbar {
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}
</style>
