<template>
  <v-layout column class="">
    <v-list
      dense
      v-for="selectOption in selectOptionsArray"
      :key="selectOption.name"
      @click.prevent.stop
      class="text-caption"
    >
      <v-list-item-title
        v-if="selectOptionsArray.length > 1"
        class="text-caption px-4 font-weight-bold"
        >{{ selectOption.name }}</v-list-item-title
      >
      <v-list-item
        v-for="(option, idx) in selectOption.options"
        :key="idx"
        @click.prevent="optionChanged(selectOption, option)"
      >
        {{ option.key }}
      </v-list-item>
    </v-list>

    <!-- <div
      class="d-flex align-center justify-center pa-2"
      v-for="selectOption in selectOptionsArray"
      :key="selectOption.name"
      @click.stop.prevent
      @mousedown.stop
    >
      <v-select
        outlined
        dense
        v-model="productSelectedOptions[selectOption.parameterName]"
        :items="selectOption.options"
        :label="selectOption.name"
        item-text="key"
        item-value="value"
        @change="optionChanged(selectOption)"
        :menu-props="{ maxWidth: '55%' }"
        :hide-details="true"
      />
    </div> -->
  </v-layout>
</template>

<style scoped lang="scss">
.menu {
  .v-input {
    font-size: 12px !important;
  }
}
</style>

<script>
import productMixin from "~/mixins/product";
export default {
  mixins: [productMixin],
  props: {
    selectOptionsArray: { type: Array }
  },
  methods: {
    optionChanged(selectOption, option) {
      // this.onOptionChanged(selectOption, option);
      console.log("optionsCHanged", selectOption, option);
      this.$emit("submit", { selectOption: selectOption, option: option });
    }
    // async onOptionChanged(selectOption, option) {
    //   this.key++;
    //   this.selectedOptions[selectOption.parameterName] = option.key;
    //   this.$emit("submit", true);
    //   console.log("PROEICTR", this.product);
    // }
  },
  created() {
    console.log("selected options array", this.selectOptionsArray);
  }
};
</script>
