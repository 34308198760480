<template>
  <v-card
    tile
    elevation="0"
    class="invite-friend-baner-card d-flex flex-column flex-sm-row justify-center align-center"
  >
    <img
      src="img_layout/gift.svg"
      width="58"
      color="primary"
      class="gift-icon"
    />
    <div class="d-flex flex-column">
      <ebsn-meta
        :target="config"
        path="categoryblocktype_InviteFriendBaner.TITLE"
        tag="h2"
        :class="'invite-friend-title text-center'"
      />
      <div class="text-center">
        <ebsn-meta
          :target="config"
          path="categoryblocktype_InviteFriendBaner.DESCRIPTION"
          tag="span"
          :class="'invite-friend-description'"
        /><span class="invite-friend-code"> {{ code }}</span>
      </div>
    </div>
    <v-btn
      large
      tile
      elevation="0"
      color="primary"
      :block="$vuetify.breakpoint.xsOnly"
      :to="{
        name: 'Category',
        params: {
          pathMatch: $ebsn.meta(
            this.config,
            'categoryblocktype_InviteFriendBaner.CATEGORY.slug'
          )
        }
      }"
      >{{ $t("invite.cta") }}</v-btn
    >
  </v-card>
</template>
<style lang="scss">
.invite-friend-baner-card {
  gap: 16px;
  background-color: transparent !important;
  padding: 8px;
  .gift-icon {
    color: var(--v-primary-base);
  }
  .invite-friend-title {
    color: var(--v-primary-lighten1);
  }
  .invite-friend-description {
    font-size: 18px;
  }
  .invite-friend-code {
    font-size: 22px;
    font-weight: bold;
  }
}
</style>
<script>
import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

import InviteService from "~/service/inviteService";

import { mapGetters } from "vuex";

export default {
  name: "InviteFriendBaner",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  components: {},
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      personalCode: null
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    code() {
      return (
        this.personalCode &&
        this.personalCode.tellFriend &&
        this.personalCode.tellFriend.code
      );
    }
  },
  methods: {},
  async created() {
    let response = await InviteService.getPersonalCode();
    if (response) {
      this.personalCode = response;
    }
  }
};
</script>
