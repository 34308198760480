<template>
  <v-row
    no-gutters
    class="cart-info-row lighten-1 py-3 px-5 rounded-sm d-flex flex-row"
    :class="homeComponent ? 'primary' : ''"
    v-if="isAuthenticated"
  >
    <v-btn
      icon
      small
      class="service-icon-btn mr-4"
      @click="openTimeslotSelector"
    >
      <v-img
        :src="`/services-icons/${serviceImage}.svg`"
        max-width="40"
      ></v-img>
    </v-btn>
    <v-col class="flex-column d-flex">
      <!-- <div class="d-flex flex-column right-col">
        <span class="px-2 white--text">{{ serviceName }}</span>
      </div> -->
      <div class="d-flex flex-column right-col">
        <div
          class="cart-info-button service-info-button v-size--x-small white--text d-flex"
          color="white"
        >
          <span class="v-btn__content"
            ><span>{{ serviceName }}</span></span
          >
        </div>
        <v-btn
          class="cart-info-button bold"
          :disabled="!editableAddress"
          text
          x-small
          block
          color="white"
          elevation="0"
          @click="openAddressSelector"
        >
          <span>{{ shippingAddress }} </span>
          <v-icon color="secondary" right v-if="editableAddress">$edit</v-icon>
        </v-btn>
        <v-btn
          class="cart-info-button"
          :disabled="!editableTimeslot"
          text
          x-small
          color="white"
          elevation="0"
          @click="openTimeslotSelector"
        >
          <span>{{ timeslot }}</span>
          <v-icon color="secondary" right v-if="editableTimeslot"
            >$edit</v-icon
          ></v-btn
        >
      </div>
    </v-col>
  </v-row>
</template>
<style scoped lang="scss">
.service-icon-btn {
  align-items: flex-start;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-top: 8px !important;
  }
}
.cart-info-row {
  flex-wrap: nowrap;
  .right-col {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      max-width: 90%;
    }
    .v-btn {
      width: 100%;
      :deep(.v-btn__content) {
        width: 100% !important;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
.service-info-button {
  font-weight: normal;
  padding: 0 9px !important;
}
.cart-info-button {
  background: transparent !important;
  justify-content: flex-start;
  height: 16px !important;
  .v-btn__content {
    max-width: 100%;
    span {
      font-size: 12px !important;
      font-weight: 400;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      // @media #{map-get($display-breakpoints, 'xs-only')} {
      //   max-width: 100%;
      // }
      // @media #{map-get($display-breakpoints, 'lg-only')} {
      //   max-width: 226px;
      // }
      // @media #{map-get($display-breakpoints, 'xs-only')} {
      //   max-width: 177px;
      // }
    }
    .v-icon {
      font-size: 12px;
    }
  }

  &.bold {
    .v-btn__content {
      span {
        font-weight: 700;
      }
    }
  }
}
</style>
<script>
import cartInfo from "~/mixins/cartInfo";
import login from "~/mixins/login";
import { mapGetters, mapState } from "vuex";
export default {
  mixins: [cartInfo, login],
  data() {
    return {
      serviceImage: null
    };
  },
  props: {
    homeComponent: { type: Boolean, default: true }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    serviceName() {
      return this.$t(
        "navbar.decodeServiceName." +
          this.cart.shippingAddress.deliveryServiceId
      );
    }
  },
  methods: {
    async openTimeSlotSelector() {
      await this.setTimeslot();
    },
    setServiceImage(serviceId) {
      if (!serviceId) serviceId = this.cart.shippingAddress.deliveryServiceId;
      if (serviceId === 1) this.serviceImage = "clicca-e-ritira-neg";
      if (serviceId === 2) this.serviceImage = "consegna-a-casa-neg";
      if (serviceId === 4) this.serviceImage = "locker-bianco";
    },
    async handleEditTimeslot() {
      await this.openTimeslotSelector();
      global.EventBus.$emit("timeslotchanged");
    }
  },
  created() {
    if (this.isAuthenticated) {
      this.setServiceImage();
    }
  },
  watch: {
    "cart.shippingAddress.deliveryServiceId": function(val) {
      this.setServiceImage(val);
    }
  }
};
</script>
