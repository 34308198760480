var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.addresses),function(address,index){return _c('v-list-item',{key:address.addressId,on:{"click":function($event){return _vm.setAddress(address, index)}}},[_c('v-list-item-avatar',[(_vm.shippingAddressSelected == index)?_c('v-icon',[_vm._v("$radioChecked")]):_c('v-icon',[_vm._v("$radioUnchecked")])],1),(_vm.showServiceIcon)?_c('img',{staticClass:"mr-3",attrs:{"src":_vm.$t('navbar.service.' + address.deliveryServiceId + '.textIcon'),"height":"50","width":"50"}}):_vm._e(),_c('div',{staticClass:"d-flex align-items justify-space-between w-100 flex-column flex-sm-row"},[_c('v-list-item-content',{staticClass:"w-100 align-self-center"},[_c('v-list-item-title',{staticClass:"font-weight-bold primary--text text--lighten-1"},[_vm._v(" "+_vm._s(address.addressName)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--color"},[_vm._v(" "+_vm._s(_vm.$t("navbar.address." + address.addressTypeId + ".list", address))+" "),(
                address.zone.zoneGroupId == 5 && _vm.$vuetify.breakpoint.smAndDown
              )?_c('span',[_c('br'),_c('strong',[_vm._v(_vm._s(_vm.$t("cart.checkoutSummary.deliveryShopopop")))])]):(address.zone.zoneGroupId == 5)?_c('span',[_vm._v(" - "),_c('strong',[_vm._v(_vm._s(_vm.$t("cart.checkoutSummary.deliveryShopopop")))])]):_vm._e()])],1),_c('div',{staticClass:"align-self-start align-self-sm-center"},[(
              address.addressId == _vm.cart.user.defaultStoreAddressId ||
                address.addressId == _vm.cart.user.defaultDeliveryAddressId
            )?_c('v-list-item-action',{staticClass:"flex-row align-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-star-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(address.addressType == "home" ? "Indirizzo preferito" : "Negozio preferito"))])])],1):_vm._e(),_vm._l((_vm.filteredAddressClass(
              address.addressClass
            )),function(repartoIcon,idx){return _c('img',{key:idx,staticClass:"ml-1 align-center",attrs:{"src":repartoIcon.iconSource,"alt":repartoIcon.name,"height":"20","width":"20"}})})],2)],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }