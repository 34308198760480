<template>
  <v-hover v-slot="{ hover }" v-if="quantity <= 0">
    <v-btn
      large
      depressed
      style="flex-grow:1"
      :class="hover ? 'grey darken-2 white--text' : 'primary lighten-1'"
      @click.stop.prevent="plus(true, { infos: { ...selectedOptions } })"
    >
      <v-icon color="white" class="mr-2">$cart</v-icon>
      <span v-if="!$vuetify.breakpoint.smAndDown || showAddToCartLabel">
        {{ addToCartLabel ? addToCartLabel : $t("product.addToCart") }}
      </span>
    </v-btn>
  </v-hover>

  <div v-else class="qty-wrap rounded" :class="{ 'not-empty': quantity > 0 }">
    <a
      role="button"
      class="minus"
      @click.stop.prevent="minus(true, { infos: { ...selectedOptions } })"
      @mousedown.stop
    >
      <v-icon>$minus</v-icon>
    </a>
    <div class="val-cnt" @click.stop.prevent="" @mousedown.stop>
      <span class="val">{{ quantity }} {{ unit }}</span>
      <span class="small">{{ quantityPerUnit }}</span>
    </div>
    <a
      role="button"
      class="plus"
      @click.stop.prevent="plus(true, { infos: { ...selectedOptions } })"
      @mousedown.stop
    >
      <v-icon>$plus</v-icon>
    </a>
  </div>
</template>
<script>
import productMixin from "~/mixins/product";

export default {
  name: "ProductCard",
  props: {
    product: { type: Object, required: true },
    item: { type: Object, required: false },
    updateCart: { type: Boolean, default: global.config.updateCart },
    selectedOptions: { type: Object, default: () => ({}) },
    showAddToCartLabel: { type: Boolean, default: true },
    addToCartLabel: { type: String, required: false }
  },
  // eslint-disable-next-line vue/no-unused-components
  mixins: [productMixin],
  computed: {
    packageCount() {
      if (this.product.productInfos.TIPOLOGIA != "Sfuso" && this.item) {
        if (
          this.product.logisticPalletItems > 0 &&
          this.item.quantity % this.product.logisticPalletItems == 0
        ) {
          return (
            this.item.quantity / this.product.logisticPalletItems + " pallet"
          );
        } else if (
          this.product.logisticPackagingItems > 0 &&
          this.item.quantity % this.product.logisticPackagingItems == 0
        ) {
          let unit = this.item.quantity / this.product.logisticPackagingItems;
          return "(" + unit + (unit == 1 ? " collo" : " colli") + ")";
        }
      }
      return "(0 colli)";
    }
  }
};
</script>
