import { render, staticRenderFns } from "./ProposalButton.vue?vue&type=template&id=04daf144&"
import script from "./ProposalButton.vue?vue&type=script&lang=js&"
export * from "./ProposalButton.vue?vue&type=script&lang=js&"
import style0 from "./ProposalButton.vue?vue&type=style&index=0&id=04daf144&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
installComponents(component, {VCard,VHover})
