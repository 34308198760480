<template>
  <div class="invite-friend-container">
    <ebsn-meta
      :target="config"
      path="categoryblocktype_InviteFriend.TITLE"
      tag="h2"
      :class="' invite-friend-title text-center '"
    />
    <ebsn-meta
      :target="config"
      path="categoryblocktype_InviteFriend.DESCRIPTION"
      tag="div"
      :class="' invite-friend-description text-center '"
    />

    <v-row class="invite-friend-row mb-5"
      ><v-col cols="12" class="text-center">
        <h3 class="code-title">
          {{ $t("invite.code.title") }}
        </h3>
        <v-row
          no-gutters
          class="text-h3 font-weight-bold code-box rounded-md mt-4 mx-auto"
          align="center"
        >
          <v-col cols="12" class="code-value text-h2"
            ><div class="flex-grow-1 font-weight-bold">
              <span v-if="code">{{ personalCode.tellFriend.code }}</span>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="copyCode"
                  ><v-icon color="primary lighten-1">$copy</v-icon></v-btn
                >
              </template>
              {{ $t("invite.code.copy") }}
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="!floating"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="() => (wantShare = !wantShare)"
                  ><v-icon color="primary lighten-1">$share</v-icon></v-btn
                >
                <v-speed-dial
                  v-else
                  v-bind="attrs"
                  v-on="on"
                  v-model="fab"
                  direction="bottom"
                  transition="slide-y-reverse-transition"
                >
                  <template v-slot:activator>
                    <v-btn icon>
                      <v-icon color="primary lighten-1">
                        $share
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                    v-for="(socialPlatform, index) in socialPlatforms"
                    :key="index"
                    fab
                    small
                    @click="shareOnPlatform(socialPlatform)"
                  >
                    <img
                      :src="`/img_layout/social_icons/${socialPlatform}.svg`"
                      :alt="socialPlatform"
                    />
                  </v-btn>
                </v-speed-dial>
              </template>
              {{ $t("invite.code.share") }}
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col></v-row
    >
    <v-row class="social-sharing-container" v-if="wantShare">
      <v-col class="d-flex flex-column justify-center align-center">
        <h2 class="primary--text text--lighten-1">
          Condividi il tuo codice "Porta un Amico"
        </h2>
        <div class="d-flex flex-wrap social-buttons my-4">
          <span
            v-for="(socialPlatform, index) in socialPlatforms"
            :key="index"
            @click="shareOnPlatform(socialPlatform)"
            class="share-icon"
          >
            <img
              :src="`/img_layout/social_icons/${socialPlatform}.svg`"
              :alt="socialPlatform"
            />
          </span>
        </div>
      </v-col>
    </v-row>
    <v-divider />

    <v-row no-gutters class="gift-certificates pt-7" v-if="giftCertificates">
      <v-col cols="12" class="text-center">
        <h3 class="gifts-title">
          {{ $t("invite.gifts.title") }}
        </h3>
        <v-row
          no-gutters
          class="count-box text-h3 font-weight-bold rounded-md grey white py-6 mt-4 mx-auto default--text"
          align="center"
        >
          <v-col cols="12">
            <div class="count font-weight-bold">
              {{ giftCertificates.length }}
            </div>
            <div class="text-body-1 font-weight-regular">
              {{ $tc("invite.gifts.friends", giftCertificates.length) }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters class="history-list my-11">
      <v-list dense class="w-100">
        <v-list-item v-for="(item, idx) in giftCertificates" :key="idx">
          <v-row justify="space-between" class="px-4">
            <div>{{ `${item.name} ${$n(item.amount, "currency")}` }}</div>
            <div>{{ item.endDate }}</div>
          </v-row>
        </v-list-item>
      </v-list>
    </v-row>

    <v-divider />
  </div>
</template>
<style lang="scss">
.invite-friend-container {
  .social-sharing-container {
    .social-buttons {
      gap: 10px;
      .share-icon {
        cursor: pointer !important;
        img {
          width: 50px;
        }
      }
    }
  }
  .invite-friend-title {
    color: var(--v-primary-lighten1);
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .invite-friend-description {
    margin-bottom: 12px;
  }
  .invite-friend-row {
    .code-title {
      font-size: 26px;
    }
    .code-box {
      border: 2px solid var(--v-primary-lighten1);
      max-width: 330px;
      min-height: 53px;
      background-color: $white;
      border-radius: 4px;
      .code-value {
        display: flex;
        align-items: center;
      }
    }
  }
  .gift-certificates {
    .gifts-title {
      font-size: 26px;
    }
    .count-box {
      border: 1px solid $border-color;
      max-width: 330px;
      border-radius: 4px;
      .count {
        font-size: 41px;
        color: var(--v-primary-lighten1);
      }
    }
  }
  .history-list {
    .v-list {
      background-color: transparent;
      .v-list-item:nth-child(odd) {
        background: var(--v-grey-lighten2);
      }
    }
  }
}
</style>
<script>
import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

import InviteService from "~/service/inviteService";

import { mapGetters } from "vuex";

export default {
  name: "InviteFriend",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true },
    floating: { type: Boolean, default: false },
    whatsapp: { type: Boolean, default: true },
    mail: { type: Boolean, default: true },
    twitter: { type: Boolean, default: true },
    facebook: { type: Boolean, default: true },
    linkedin: { type: Boolean, default: true },
    telegram: { type: Boolean, default: true }
  },
  components: {},
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      personalCode: null,
      wantShare: false,
      fab: false,
      socialPlatforms: null
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    code() {
      return (
        this.personalCode &&
        this.personalCode.tellFriend &&
        this.personalCode.tellFriend.code
      );
    },
    giftCertificates() {
      if (this.personalCode && this.personalCode.userGiftCertificates) {
        return this.personalCode && this.personalCode.userGiftCertificates;
      }
      return [];
    }
  },
  methods: {
    copyCode() {
      if (this.personalCode.tellFriend && this.personalCode.tellFriend.code) {
        navigator.clipboard.writeText(this.personalCode.tellFriend.code);
        global.EventBus.$emit("success", {
          message: global.EventBus.$t("invite.code.copied")
        });
      }
    },
    shareOnPlatform(platform) {
      // const title = encodeURIComponent(
      //   `Ricevi 5€ di sconto sulla Spesa Online`
      // );
      // const text = encodeURIComponent(
      //   `Fai la spesa online su Eurospin e ricevi 5€, usa il mio codice sconto: ${this.personalCode.tellFriend.code}`
      // );

      const title = encodeURIComponent(
        this.$t(`invite.socialButtons.${platform}.title`)
      );

      const text = encodeURIComponent(
        this.$t(`invite.socialButtons.${platform}.text`, [
          this.personalCode.tellFriend.code
        ])
      );

      const currentPage = window.location.href;

      let url;

      switch (platform) {
        case "whatsapp": {
          url = `https://wa.me/?text=${text}`;
          break;
        }

        case "mail": {
          url = `mailto:?subject=${title}&body=${text}`;
          break;
        }

        case "twitter": {
          url = `https://twitter.com/intent/tweet?url=${currentPage}&text=${text}`;
          break;
        }

        case "facebook": {
          url = `https://www.facebook.com/sharer/sharer.php?u=${currentPage}&quote=${text}`;
          break;
        }

        case "linkedin": {
          url = `https://www.linkedin.com/sharing/share-offsite/?url=${currentPage}&title=${title}&summary=${text}`;
          break;
        }

        case "telegram": {
          url = `https://t.me/share/url?url=${currentPage}&text=${text}`;
          break;
        }

        default:
          console.error("Unsupported platform");
          return;
      }

      window.open(url, "_blank");
    }
  },
  async created() {
    let response = await InviteService.getPersonalCode();
    if (response) {
      this.personalCode = response;
    }
    const enabledSocialPlatforms = {
      whatsapp: this.whatsapp,
      mail: this.mail,
      twitter: this.twitter,
      facebook: this.facebook,
      linkedin: this.linkedin,
      telegram: this.telegram
    };
    this.socialPlatforms = Object.keys(enabledSocialPlatforms).filter(
      socialPlatform => enabledSocialPlatforms[socialPlatform] === true
    );
  }
};
</script>
