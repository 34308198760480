<template>
  <div class="share-button">
    <v-tooltip bottom v-if="!exploded">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="!floating"
          v-bind="attrs"
          v-on="on"
          icon
          @click="() => (wantShare = !wantShare)"
          ><v-icon :color="color">$share</v-icon></v-btn
        >
        <v-speed-dial
          v-else
          v-bind="attrs"
          v-on="on"
          v-model="fab"
          :direction="$vuetify.breakpoint.xs ? 'bottom' : 'right'"
          transition="slide-y-reverse-transition"
          class="share-btn-speed-dial"
        >
          <template v-slot:activator>
            <v-btn icon>
              <v-icon :color="color">
                $share
              </v-icon>
            </v-btn>
          </template>
          <v-btn
            v-for="(socialPlatform, index) in socialPlatforms"
            :key="index"
            fab
            small
            height="40"
            width="40"
            @click="shareOnPlatform(socialPlatform)"
          >
            <img
              :src="`/img_layout/social_icons/${socialPlatform}.svg`"
              :class="`social-platform ${socialPlatform}`"
            />
          </v-btn>
        </v-speed-dial>
      </template>
    </v-tooltip>
    <v-row class="social-sharing-container" v-if="wantShare">
      <v-col class="d-flex flex-column justify-center align-center">
        <h3 class="primary--text text--lighten-1" v-html="title"></h3>
        <div class="d-flex flex-wrap social-buttons my-4">
          <span
            v-for="(socialPlatform, index) in socialPlatforms"
            :key="index"
            @click="shareOnPlatform(socialPlatform)"
            class="share-icon"
          >
            <img :src="`/img_layout/social_icons/${socialPlatform}.svg`" />
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.share-button {
  .share-btn-speed-dial {
    .v-speed-dial__list {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        position: relative;
      }
    }
  }
  .social-sharing-container {
    .social-buttons {
      gap: 10px;
      .share-icon {
        cursor: pointer !important;
        img {
          width: 40px;
          max-width: 40px;
        }
      }
    }
  }
}
.social-platform {
  height: 40px;
  width: 40px;
}
</style>
<script>
import { mapGetters } from "vuex";
import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";
export default {
  name: "ShareButton",
  props: {
    config: { type: Object, required: false },
    exploded: { type: Boolean, default: true },
    floating: { type: Boolean, default: false },
    color: { type: String, default: "primary lighten-1" }
  },
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      wantShare: false,
      fab: false,
      socialPlatforms: null,
      whatsapp: true,
      mail: true,
      twitter: true,
      facebook: true,
      linkedin: true,
      telegram: true,
      title: null
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    shareOnPlatform(platform) {
      let title = null;
      let text = null;
      if (this.config) {
        let platformTitle = platform.toUpperCase() + "_TITLE";
        let platformDescr = platform.toUpperCase() + "_DESCR";
        title = encodeURIComponent(
          this.config.metaData.categoryblocktype_ShareWithFriends[
            platformTitle.toUpperCase()
          ]
        );
        text = encodeURIComponent(
          this.config.metaData.categoryblocktype_ShareWithFriends[
            platformDescr.toUpperCase()
          ]
        );
      } else {
        title = encodeURIComponent(
          this.$t(`sharing.socialButtons.${platform}.title`)
        );

        text = encodeURIComponent(
          this.$t(`sharing.socialButtons.${platform}.text`)
        );
      }

      const currentPage = window.location.href;
      let url;

      switch (platform) {
        case "whatsapp": {
          url = `https://wa.me/?text=${text} ${currentPage}`;
          break;
        }

        case "mail": {
          url = `mailto:?subject=${title}&body=${text} ${currentPage}`;
          break;
        }

        case "twitter": {
          url = `https://twitter.com/intent/tweet?url=${currentPage}&text=${text}`;
          break;
        }

        case "facebook": {
          url = `https://www.facebook.com/sharer/sharer.php?u=${currentPage}&quote=${text}`;
          break;
        }

        case "linkedin": {
          url = `https://www.linkedin.com/sharing/share-offsite/?url=${currentPage}&title=${title}&summary=${text}`;
          break;
        }

        case "telegram": {
          url = `https://t.me/share/url?url=${currentPage}&text=${text}`;
          break;
        }

        default:
          console.error("Unsupported platform");
          return;
      }

      window.open(url, "_blank");
    }
  },
  async mounted() {
    if (this.config) {
      this.floating = this.config.metaData.categoryblocktype_ShareWithFriends.FLOATING;

      this.whatsapp = this.config.metaData.categoryblocktype_ShareWithFriends.WHATSAPP;
      this.mail = this.config.metaData.categoryblocktype_ShareWithFriends.MAIL;
      this.twitter = this.config.metaData.categoryblocktype_ShareWithFriends.TWITTER;
      this.facebook = this.config.metaData.categoryblocktype_ShareWithFriends.FACEBOOK;
      this.linkedin = this.config.metaData.categoryblocktype_ShareWithFriends.LINKEDIN;
      this.telegram = this.config.metaData.categoryblocktype_ShareWithFriends.TELEGRAM;

      this.title = this.config.metaData.categoryblocktype_ShareWithFriends.TITLE;
    } else {
      this.title = this.$t("invite.generic.tittle");
    }

    const enabledSocialPlatforms = {
      whatsapp: this.whatsapp,
      mail: this.mail,
      twitter: this.twitter,
      facebook: this.facebook,
      linkedin: this.linkedin,
      telegram: this.telegram
    };
    this.socialPlatforms = Object.keys(enabledSocialPlatforms).filter(
      socialPlatform => enabledSocialPlatforms[socialPlatform] === true
    );

    if (this.exploded) {
      this.wantShare = true;
    }
  }
};
</script>
