import Vue from "vue";
import dayjs from "dayjs";
import it from "dayjs/locale/it";
import customParseFormat from "dayjs/plugin/customParseFormat";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);
dayjs.extend(updateLocale);
dayjs.locale(it);

dayjs.updateLocale("it", {
  months: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre"
  ]
});

Object.defineProperties(Vue.prototype, {
  $dayjs: {
    get() {
      return dayjs;
    }
  }
});
const InvalidReturn = "";

Vue.filter("dayjs", (value, method, ...params) => {
  let d = dayjs(value);
  if (!d.isValid()) return InvalidReturn;
  if (method) {
    return d[method].apply(d, params);
  }
});
